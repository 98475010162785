import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Typography,
  StepConnector, stepConnectorClasses 
} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";
import { 
           logo, notify, msgIcon, topImage, petIcon ,  indoorIcon,  outdoorIcon,
           techIcon,  orgIcon ,  runIcon ,  visitIcon, cropImage, 
           paper1, paper2,  paper3, paper4, paper5, paper6, paper7, tickIcon,
           timer, mapIcon, calIcon, estimate,gradient, capIcon, teleIcon,  msg2Icon, 
           userIcon, activeCat, activeHanger, activeIndoor, activeOutdoor, activeMsg,
           activeVisit, deactiveTech,
           successIcon
          }
            from "./assets"
          import { styled } from "@mui/styles";
          import Stepper from "@mui/material/Stepper";
          import Step from "@mui/material/Step";
          import StepLabel from "@mui/material/StepLabel";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
   HorizontalLinearAlternativeLabelStepper = () => {
    const steps = [
      "Send a Request for Help",
      "We Find a Teen Helper",
      "Get Assistance Fast",
    ];
    const activeStep = 2; // Change this value to test different active steps
  
    return (
      <Box>
      <Wrapper sx={{ width: "100%" }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            '& .MuiStepIcon-root.Mui-active': {
              border: '2px solid #5E70DD',
              borderRadius: "50%",
              color: "white"
            },
          }}
          connector={<CustomStepConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Wrapper>
      </Box>

    );
  }
    // Customizable Area End
  render() {
    // Customizable Area Start
    let images = [
      {"img": deactiveTech, text:"Tech Help", name:"Tech help"},
      {"img":indoorIcon, text:"Indoor Help",name:"Indoor help"},
      {"img":outdoorIcon, text:"Outdoor Help",name:"Outdoor help"},
      {"img":visitIcon, text: "Friendly Visits",name:"Friendly Visit"},
      {"img":orgIcon, text:"Organizing",name: "Organizing or Decluttering"},
      {"img":petIcon, text:"Pet Care",name: "Dog or Cat Care:"},
      {"img":runIcon, text:"Run Errands", name: "Run Errands"},
    ]
    let imageObject:any = {
      0: techIcon.default,
      1: activeIndoor.default,
      2: activeOutdoor.default,
      3: activeVisit.default,
      4: activeMsg.default,
      5: activeCat.default,
      6: activeHanger.default,
    }
  
    let paperContent = [
      {"img": paper1, "title": "Tech Help", "content": "Your teen assistant can help you troubleshoot and fix minor issues with apps, logins, or wifi for your phone, laptop, or other devices."},
      {"img": paper2, "title": "Indoor Help", "content": "Your teen assistant can help you change lightbulbs, carry in boxes or groceries, water and maintain houseplants, help with meal prep, get laundry and housekeeping done, and more."},
      {"img": paper3, "title": "Outdoor Help", "content": "Your teen assistant can mow and edge your lawn, plant seeds or starters, spread mulch, blow or rake debris, and more."},
      {"img": paper4, "title": "Friendly Visits", "content": "Your teen assistant will stop by at a scheduled time to visit, play games or help with hobbies, and ask if you need help with anything. We'll send a brief report of the visit to you and your trusted circle."},
      {"img": paper5, "title": "Organizing", "content": "Your teen assistant can help you sort through, organize, box up, label, and arrange items in your cabinets, closets, garage, or shed. If you have items to sell online or donate, your teen assistant can help with this too."},
      {"img": paper6, "title": "Pet Care", "content": "Your teen assistant can walk your dog, change the litter box, fill pet food bins, brush or play with your furry family member, clean out the fish tank, and more."},
      {"img": paper7, "title": "Run Errands", "content": "Your teen assistant will use their own transportation to pick up groceries or small household items, pick up mail or packages, drop off items for shipping, take items to charity donation sites, and more."},
    ]
  
    return (

      <Box style={{ overflowX: "hidden" }}>
        <MainNav>
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "95%" }}>
              <img src={logo.default} style={{ width: "195px", height: "21px" }} />
              <StyledImage src={userIcon.default} />
            <NavWrap>
              <Box></Box>
              <Typography style={{
                fontFamily: "Manrope", fontSize: "16px", fontWeight: 700, lineHeight: "25.6px",
                textAlign: "center", color: "#39393F"
              }} onClick={this.handleNavigation.bind(this,"UserProfileBasicBlock")}
              data-test-id = "UserProfileBasicBlockId">{this.state.userName?.fullName}</Typography>
              <Box style={{ display: "flex", gap: "10px" }}>
                <img src={notify.default} />
                <img src={msgIcon.default} />
              </Box>
            </NavWrap>
          </Box>
        </MainNav>
        <Box style={{ width: "100%" }}>
          <Typography style={webStyle.topText}>We're on a mission disguised as a high school job: Help others. Ensure safety. Build skills. End loneliness.</Typography>
          <Box style={{ position: "relative",width: "100%" , height:"476px", }}>
            <img src={topImage.default} style={{ width: "100%"}} />
            <Box style={{width:"100%", position: "absolute", top: "40%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <Box>
            <Box style={{ width: "100%", position:"absolute", left:"17%" }}>
              <Typography style={{...webStyle.imgText, maxWidth: "487px"}}>SENIOR YEAR IS AN ELITE SQUAD OF TEENS HELPING SENIORS IN YOUR LOCAL COMMUNITY</Typography>
              
              <Box >
                <Typography style={{
                fontFamily: "Caudex", fontSize: "48px", fontWeight: 700, lineHeight: "52.8px",color:"#FFFFFF"
              }}>Book trusted teens to help out.</Typography>
            </Box>
                </Box>

            </Box>
              </Box>
              <StyledWrapper>
                <StyledBox>
                      <Box style={{display:"flex",marginTop:"40px", gap:"10px", flexWrap: "wrap", paddingLeft:"25px"}}>
                      {
                       images.map((item:any, index:number)=> 
                        <Box style={{display:"flex"}}>
                          <Box style={{display:"flex", justifyContent:"center", flexDirection:"column", alignItems: "center"}}>
                          <img src={(this.state.activeIndex === index) ? imageObject[this.state.activeIndex] : item.img.default} style={{height:"40px",width:"40px", color: `${(this.state.activeIndex === index) ? "red" : "blue" }` }} onClick={() => this.filterCategories(item.name, index)}/>
                          <Typography style={{...webStyle.iconText, color:`${(this.state.activeIndex === index) ? "#5E70DD" : "#AABED3" }`}}>{item.text}</Typography>

                          </Box>
                        </Box>
                      )
                     }
                     </Box>
                     <Box style={{ display:"flex", flexDirection:"column", marginTop:"30px", marginLeft: "20px"}}>
                     <Typography style={{ fontFamily: "Manrope", fontSize: "15px", fontWeight: 600, 
                     lineHeight: "20.49px", color:"#130055"}}>Choose service</Typography>
                    <Box style={{display:"flex", gap:"12px", marginTop:"30px", flexWrap: "wrap"}}>
                      {
                        this.state.filteredCategory?.map((item:any)=>
                          <Button style={webStyle.btn}>{item?.name}</Button>
                      )
                      }
                    </Box>
                    <BoxWrapper>
                      <Select
                      defaultValue={1}
                      style={webStyle.selectBox}
                       startAdornment={
                        <InputAdornment position="start">
                          <img src={mapIcon.default} style={{color:""}}/>
                        </InputAdornment>
                      }
                      >
                        <MenuItem key={1} value={1} >Select Location</MenuItem>
                      </Select>
                      <Select
                      defaultValue={1}
                      style={webStyle.selectBox}
                       startAdornment={
                        <InputAdornment position="start">
                          <img src={calIcon.default} style={{color:""}}/>
                        </InputAdornment>
                      }
                      >
                        <MenuItem key={1} value={1} >Select Date</MenuItem>
                      </Select>

                      <Select
                      defaultValue={1}
                      style={webStyle.selectBox}
                       startAdornment={
                        <InputAdornment position="start">
                          <img src={timer.default} style={{color:""}}/>
                        </InputAdornment>
                      }
                      >
                        <MenuItem key={1} value={1} >Estimated Hours</MenuItem>
                      </Select>

                      <Select
                      defaultValue={1}
                      style={webStyle.selectBox}
                       startAdornment={
                        <InputAdornment position="start">
                          <img src={estimate.default} style={{color:""}}/>
                        </InputAdornment>
                      }
                      >
                        <MenuItem key={1} value={1} >Frequency</MenuItem>
                      </Select>
                      <StyledButton  data-test-id = "applyId12" onClick={() => this.setState({ successModal: true})}>Search</StyledButton>
                    </BoxWrapper>
                     </Box>

               </StyledBox>
               </StyledWrapper>
          </Box>
          <Container>
                <Typography style={webStyle.lowerText}>Do more with helpful young people.</Typography>
               <BoxContainer>
                <img src={cropImage.default} />
                <Box style={{display: "flex", maxWidth:"415.41px", width:"100%", flexDirection: "column", gap:"20px",height: "447px",  justifyContent: "center"}}>
                    <Typography style={{ fontFamily: "Manrope", color:"#0B132A", fontSize: "35px", fontWeight: 500, lineHeight: "50px", 
                    }}>Book Teens you can trust</Typography>
                    <Box style={{display:"flex", flexDirection:"column", gap:"5px"}}>
                    <Box style={{display:"flex",height:"30px", alignItems:"center", gap:"5px"}}>
                  <img src={tickIcon.default} style={{width:"24px", height:"24px"}} />
                  <Typography style={webStyle.tickTypo}>All teens pass a background check</Typography>
                </Box>
                <Box style={{display:"flex", height:"30px", alignItems:"center", gap:"5px"}}>
                  <img src={tickIcon.default} style={{width:"24px", height:"24px"}} />
                  <Typography  style={webStyle.tickTypo}>All teens have local teacher recommendations</Typography>
                </Box>
                <Box style={{display:"flex",height:"30px", alignItems:"center", gap:"5px"}}>
                  <img src={tickIcon.default} style={{width:"24px", height:"24px"}} />
                  <Typography  style={webStyle.tickTypo}>All teens are approved by our team</Typography>
                </Box>
                    </Box>
                
                </Box>
               </BoxContainer>
               <Box style={{ display:"flex", width:"100%", justifyContent: "center"}}>
               <Box style={{ width:"100%", maxWidth: "1149px"}}>
               <PaperWrap>
                {
                  paperContent.map((item: any)=>
                    <PaperColor>
                   <Box style={{paddingTop:"30px", paddingLeft:"30px"}}>
                    <Box style={{display:"flex", gap:"10px"}}>
                      <img src={item.img.default} style={{ width:"30px", height:"30px" }} />
                      <Typography  style={webStyle.contentTitle} >{item.title}</Typography>
                    </Box>
                    <Typography style={{...webStyle.contentTypo, marginTop: "20px" }}>{item.content} </Typography>
                    </Box>
                </PaperColor>
                  )
                }
                </PaperWrap>

                </Box>
                </Box>
                   <Box style={{display:"flex", justifyContent: "center", marginTop:"30px", backgroundSize:"cover", width:"100%",
                        height: "110px", border: "1px",  backgroundImage: `url(${gradient.default})`}}>
                    <Typography style={{fontFamily: "Manrope", fontSize: "32px", color: "#000000", marginTop:"30px",
                     fontWeight: 700, lineHeight: "43.71px"}}>A Note on Safety</Typography>
                   </Box>
                <Box style={{ display:"flex", width:"100%", justifyContent: "center", flexDirection:"column", alignItems:"center"}}>
                  <Box style={{maxWidth: "1149px", width:"100%", borderRadius: "10px", backgroundColor: "#F1F5F9", marginTop: "20px"}}>
                   <Box style={{ display:"flex", flexDirection: "column", alignItems:"center", gap:"20px"}}>
                     <ContentWrap>
                      <Box className="contenTexttWrap">
                        <Box>
                      <Typography style={webStyle.typos}>We send an adult to conduct an onsite visit prior to sending any teen to your home or company.
                      </Typography>
                        <span style={webStyle.spanText }> This helps us help you better, and helps keep our teens safe.</span>
                        </Box>
                      <Typography style={webStyle.typos}>Our teens are booked in pairs to promote safety, build skills, and help you better.</Typography>
                      <Typography style={webStyle.typos}>Our teens are not qualified to assist with medical needs, reduced mobility, or transportation of others.</Typography>
                      </Box>
                      <Box><Typography style={webStyle.typos}>They do not provide help with:</Typography>
                      <ul style={{marginTop: "0px"}}>
                        <li style={webStyle.spanText}>
                        physical support for people with reduced mobility
                        </li>
                        <li style={webStyle.spanText}>
                        driving services
                        </li>
                        <li style={webStyle.spanText}>
                        activities of daily living such as bathing, toileting, getting dressed, walking, eating, or personal hygiene
                        </li>
                        <li style={webStyle.spanText}>
                        medical care or physical therapy
                        </li>
                        <li style={webStyle.spanText}>
                        cleaning or handling bodily fluids, soiled laundry or furniture, bathrooms or toilets
                        </li>
                        <li style={webStyle.spanText}>
                        personal caregiver services
                        </li>
                        <li style={webStyle.spanText}>
                        help with, oversight of, or verification of taking medications or vitamin supplements
                        </li>
                        <li style={webStyle.spanText}>
                        rides to or from errands or appointments
                        </li>
                        <li style={webStyle.spanText}>
                        pick-up services for medical prescriptions or regulated substances
                        </li>
                      </ul>
                      </Box>
                     </ContentWrap>
                   </Box>

                  </Box>
                  <Box style={{...webStyle.gradientBox,marginTop:"100px", backgroundImage: `url(${gradient.default})`}}>
                  <Box style={{   paddingTop:"113px", paddingBottom:"114px", display: "flex",  flexDirection: "column", alignItems: "center", }}>
                  <StyledTyp>We're on a mission disguised as a high school job:</StyledTyp>
                  <Typography style={{fontFamily: "Caudex", fontSize: "32px",
                   fontWeight: 400, lineHeight: "35.2px", color:"#353535", marginTop:"20px", textAlign: "center"}}>
                    Help others. Ensure safety. Build skills. End loneliness.</Typography>
                    <GradWrap>
                      <Box style={{display:"flex", flexDirection:"column", gap:"20px", maxWidth: "462px",  width:"100%"}}>
                        <Typography>Teens have real financial needs: transportation, saving for college, helping with family finances</Typography>
                        <Typography>When you hire Senior Year teens,  you help high school students meet financial goals, develop a strong work ethic, and gain work experience that looks great on future college and job applications</Typography>
                      </Box>
                      <Box style={{display:"flex", flexDirection:"column", gap:"20px", maxWidth: "462px", width:"100%"}}>
                        <Typography>Senior Year teens help you DO more;  you help them BE more. You help teens value positive community connections now, and wherever they're headed next</Typography>
                        <Typography>And when we say end loneliness we don't necessarily mean our clients; we mean our teens too. Intergenerational interactions benefit everyone!</Typography>
                      </Box>
                    </GradWrap>
                  </Box>
                </Box>
                </Box>
                <Box style={{textAlign:"center", paddingTop:"20px"}}>
                <Typography style={webStyle.caudText}>Teens do the work. You're in charge.</Typography>  
                      <Button style={webStyle.localTeen}>Book a Local Teen Assistant</Button>
                </Box>
            <BottomWrapper>
              <Box style={{maxWidth: "457px", width:"100%", height: "203px", marginTop:"30px" }}>
                <img src={capIcon.default} />
                <Typography style={{
                  fontFamily: "Manrope", fontSize: "18px", fontWeight: 400, lineHeight: "25.2px",
                  color: "#FFFFFF", marginTop:"20px"
                }}>Tristique elementum, ac maecenas enim fringilla placerat scelerisque semper.</Typography>
                <Typography style={{...webStyle.terms,fontSize:"14px", marginTop:"30px"}}>CompanyName @ 202X. All rights reserved</Typography>
              </Box>
                <Box>
              <Box style={{display:"flex", marginTop:"70px", gap: "20px", flexWrap: "wrap", justifyContent: "center"}}>
                <Box style={{ display: "flex", gap: "10px" }}>
                  <img src={msg2Icon.default} style={{width: "36px", height: "36px"}} />
                  <Box>
                    <Typography style={webStyle.footerTypo}>Contact us at</Typography>
                    <Typography style={webStyle.footerTitle}>senioryear@outlook.com</Typography>
                  </Box>
                </Box>

                <Box style={{ display: "flex", gap: "10px" }}>
                  <img src={teleIcon.default} style={{width: "36px", height: "36px"}} />
                  <Box>
                    <Typography style={webStyle.footerTypo}>Have a question?</Typography>
                    <Typography style={webStyle.footerTitle}>310-437-2766</Typography>
                  </Box>
                </Box>

                <Box>
                <Button style={webStyle.signupBtn}>Sign Up</Button>
                </Box>
              </Box>
              <TermsWrapper>
              <Box style={{display:"flex", gap:"20px", marginTop:"76px"}}>
                <Typography style={webStyle.terms} onClick={this.goTermsConditions}>Terms & Conditions</Typography>
              </Box>
              </TermsWrapper>
              </Box>
            </BottomWrapper>
            <BottomWrapperss>
              <Box style={{maxWidth: "457px", width:"100%", height: "90px", marginTop:"30px" }}>
                <img src={capIcon.default} style={{paddingLeft:"20px", width: "32.14px", height: "25.59px"}} />
                <Box style={{display:"flex", padding:"0px 20px", alignItems:"center"}}>
                <Typography style={{
                  fontFamily: "Manrope", fontSize: "10.71px", fontWeight: 400, lineHeight: "15px",
                  color: "#FFFFFF", marginTop:"10px"
                }}>Tristique elementum, ac maecenas enim fringilla placerat scelerisque semper.</Typography>
                <Button style={{...webStyle.signupBtn, width:"160px", borderRadius:"10px"}} onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")} >Sign Up</Button>
                </Box>
              </Box>
                <Box>
              <Box style={{display:"flex", paddingLeft: "20px", gap: "20px", flexWrap: "wrap"}}>
                <Box style={{ display: "flex", gap: "10px" }}>
                  <img src={msg2Icon.default} style={{width: "23.43px", height: "23.43px"}} />
                  <Box>
                    <Typography style={{fontFamily: "Manrope", fontSize: "7.81px", fontWeight: 400, lineHeight: "10.67px", color:"#ECECEC"}}>Contact us at</Typography>
                    <Typography style={{
                      fontFamily: "Manrope",
                      fontSize: "10.41px",
                      fontWeight: "400",
                      lineHeight: "14.22px",
                      color:"#FFFFFF"
                      }}>senioryear@outlook.com</Typography>
                  </Box>
                </Box>

                <Box style={{ display: "flex", gap: "10px" }}>
                  <img src={teleIcon.default} style={{width: "23.43px", height: "23.43px"}} />
                  <Box>
                    <Typography style={{fontFamily: "Manrope", fontSize: "7.81px", fontWeight: 400, lineHeight: "10.67px", color:"#ECECEC"}}>Have a question?</Typography>
                    <Typography style={{fontFamily: "Manrope",
                      fontSize: "10.41px",
                      fontWeight: "400",
                      color:"#FFFFFF",
                      lineHeight: "14.22px"}}>310-437-2766</Typography>
                  </Box>
                </Box>
              </Box>
              <Box style={{display:"flex", gap:"20px", marginTop:"26px",marginBottom:"26px", paddingLeft:"20px"}}>
                <Typography style={{fontFamily: "Roboto",
                fontSize: "9.21px",
                fontWeight: "400",
                lineHeight: "12.9px",
                color:"#FFFFFF"
                }}>CompanyName @ 202X. All rights reserved</Typography>

                <Typography style={{...webStyle.terms, fontSize:"12.1px", lineHeight:"12.1px"}} onClick={this.goTermsConditions}>Terms & Conditions</Typography>
              </Box>
              </Box>
            </BottomWrapperss>
               </Container>
                <Modal
                open={this.state.search}
                onClose={this.handleCloseModal}
                style={{
                  display:"flex", justifyContent:"center", alignItems:"center"
                }}
                >
            <StepperWrapper>
            <Box className="maintainWrap">
            <Select
                      defaultValue={1}
                      style={{...webStyle.selectBox3, maxWidth:"158px"}}
                      sx={{ 
                        '& .MuiInputBase-root': { height: '50px' } 
                      }}
                       startAdornment={
                        <InputAdornment position="start">
                          <img src={mapIcon.default} />
                        </InputAdornment>
                      }
                      >
                        <MenuItem key={1} value={1} >Tech help</MenuItem>
                      </Select>

                      <Select
                      defaultValue={1}
                      style={{...webStyle.selectBox3, maxWidth:"180px"}}
                      sx={{ 
                        '& .MuiInputBase-root': { height: '50px' } 
                      }}
                       startAdornment={
                        <InputAdornment position="start">
                          <img src={paper1.default} style={{width:"22.86", height:"22.86"}}/>
                        </InputAdornment>
                      }
                      >
                        <MenuItem key={1} value={1} >Select Location</MenuItem>
                      </Select>
                      <Select
                      defaultValue={1}
                      style={{...webStyle.selectBox3, maxWidth:"208px"}}
                      sx={{ 
                        '& .MuiInputBase-root': { height: '50px' } 
                      }}
                       startAdornment={
                        <InputAdornment position="start">
                          <img src={calIcon.default} />
                        </InputAdornment>
                      }
                      >
                        <MenuItem key={1} value={1} >Select Date</MenuItem>
                      </Select>

                      <Select
                      defaultValue={1}
                      style={{...webStyle.selectBox3, maxWidth:"180px"}}
                      sx={{ 
                        '& .MuiInputBase-root': { height: '50px' } 
                      }}
                       startAdornment={
                        <InputAdornment position="start">
                          <img src={timer.default} />
                        </InputAdornment>
                      }
                      >
                        <MenuItem key={1} value={1} >Estimated Hours</MenuItem>
                      </Select>

                      <Select
                      defaultValue={1}
                      style={{...webStyle.selectBox3, maxWidth:"180px"}}
                      sx={{ 
                        '& .MuiInputBase-root': { height: '50px' } 
                      }}
                       startAdornment={
                        <InputAdornment position="start">
                          <img src={estimate.default} style={{color:""}}/>
                        </InputAdornment>
                      }
                      >
                        <MenuItem key={1} value={1} >Frequency</MenuItem>
                      </Select>
                      <StyledButton >Apply</StyledButton>
                    </Box>
              <Box style={{ maxWidth:"441px", width:"100%", paddingTop:"117px", paddingBottom:"70px"}}>
                <Box style={{textAlign:"center"}}>
                <Typography className="thankText">Thank you for your request.</Typography>
                <Typography className="thankText">We are working hard to find the best candidate for you.</Typography>
                <Typography className="littleText">Shortly you will find a confirmation in your email.</Typography>
                </Box>
            {this.HorizontalLinearAlternativeLabelStepper()}
              </Box>
            </StepperWrapper>

                </Modal>
                <Modal
                open={this.state.successModal}
                onClose={this.handleCloseModal}
                style={{
                  display:"flex", justifyContent:"center", alignItems:"center"
                }}
                >
                  <Box style={{  width:"100%", maxWidth: "605.38px",  height: "379px",  borderRadius: "24.79px", backgroundColor:"white",
                    display: "flex",  flexDirection: "column",  alignItems: "center",  justifyContent: "center",  gap: "20.2px"
                  }}>
                  <img src={successIcon.default} style={{width:"52.2px", height:"52.14px"}}/>
                  <Typography
                  style={{fontFamily: "Manrope",
                    fontSize: "21.63px",
                    fontWeight: 700,
                    lineHeight: "29.55px",
                    color:"#5E70DD"
                    }}>SUCCESS</Typography>
                      <Box style={{textAlign:"center"}}>
                <Typography className="thankText">Thank you for your request.</Typography>
                <Typography className="thankText">We are working hard to find the best candidate for you.</Typography>
                <Typography className="littleText" style={{ paddingBottom:"20px"}}>Shortly you will find a confirmation in your email.</Typography>
                <StyledButton style={{fontWeight: 600, width:"236.63px", height:"45.07px", padding:"0px"}}
                onClick={this.handleModal}
                >Continue</StyledButton>
                </Box>
                    </Box>
                </Modal>

        </Box>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const webStyle = {
  headerText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "25.6px",
    color:"#020202"
  },
  typos: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "21.86px",
    color: "#000000"
  },
  spanText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "21.86px",
    color: "#000000"
  },
  topText: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "19.6px",
    color: "#1C1E1F",
    textAlign: "center" as "center",
    margin: "5px"
  },
  imgText: {
    fontFamily: "Manrope",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "25.2px",
    color: "#FFFFFF"
  },
  iconText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "25.6px",
    color: "#AABED3"
  },
  lowerText: {
    fontFamily: "Caudex",
    fontSize: "48px",
    fontWeight: 400,
    lineHeight: "52.8px",
    color: "#444444",
    textAlign: "center" as "center"
  },
  contentTitle: {
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32.78px",
    color: "#000000"
  },
  contentTypo: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "21.86px",
    width: "301px"
  },
  btn: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "25.6px",
    backgroundColor:"#F1F5F9",
    color: "#2E2E2E",
    textTransform: "capitalize" as "capitalize",
    borderRadius: "10px"

  },
  selectBox: {
    width: "180px",
    height: "49.88px",
    fontFamily: "Manrope",
    color: "#161C2D",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "-0.11px",
    borderRadius: "10px"
  },
    selectBox3: {
    width: "100%",
    height: "49.88px",
    fontFamily: "Manrope",
    color: "#161C2D",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "-0.11px",
    borderRadius: "10px"
  },
  sliderContainer: {
    width: "1150px",
  },
  textTitle: {
    fontFamily: "Manrope",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "36.4px",
    letterSpacing: "-0.01em",
    color: "#2D2D2D"
  },
  localTeen: {
    maxWidth: "335px",
    width: "100%",
    height: "55px",
    padding: "16px",
    borderRadius: "12px",
    border: "2px",
    backgroundColor: "#5E70DD",
    color:"#FFFFFF",
    fontFamily: "Manrope",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.5px",
    textTransform: "capitalize" as "capitalize",
    marginTop: "30px"

  },
  gradientBox: {
    width: "100%",
    backgroundSize: "cover",
  },
  caudText: {
    fontFamily: "Caudex",
    fontSize: "40px",
    fontWeight: 400,
    lineHeight: "44px",
    color: "#353535"
  },
  footerTypo: {
    fontFamily: "Manrope",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16.39px",
    color:"#ECECEC"
  },
  footerTitle: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "21.86px",
    color: "#FFFFFF"
  },
  terms: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#FFFFFF"
  },
  signupBtn: {
    width: "108px",
    height: "34px",
    textTransform: "capitalize" as "capitalize",
    color:"#FFFFFF",
    backgroundColor: "#000000",
    fontFamily: "Epilogue",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "25.6px"
  },
  tickTypo: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "30px",
    color:"#4F5665"
  }
};
const StyledBox = styled(Box)({
  background: "#FFFFFF",
  boxShadow: "7px 9px 36.1px 0px #0000000F",
  maxWidth: "1023px",
  width: "100%",
  paddingBottom: "20px",
  borderRadius: "8px",
})
const StyledWrapper = styled(Box)({
  position: "absolute",
  top: "70%", width: "100%",
  justifyContent: "center",
  display: "flex",
  "@media (max-width: 768px)": {
    top: "26%"
  },
})

const BoxWrapper = styled(Box)({
  display: "flex",
  gap: "16px",
  marginTop: "30px",
  flexWrap: "wrap",
  "@media (max-width: 768px)": {
    justifyContent: "center"
  },
})
const Container = styled(Box)({
  width: "100%",
  marginTop: "270px",
  "@media (max-width: 768px)": {
    marginTop: "430px",
    backgroundColor: "#F1F5F9"
  },
})

const BoxContainer = styled(Box)({
  width: "90%",
  display: "flex",
  paddingTop: "10px",
  justifyContent: "space-around",
  "@media (max-width: 768px)": {
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  },
})

const PaperWrap = styled(Box)({
  display: "flex",
  gap: "10px",
  flexWrap: "wrap",
  paddingTop: "100px",
  "@media (max-width: 768px)": {
    paddingTop: "0px",
    justifyContent: "center"
  },
})
const PaperColor = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxWidth: "367px",
  width: "100%",
  height: "236px",
  backgroundColor: "#F1F5F9",
  borderRadius: "10px",
  "@media (max-width: 768px)": {
    backgroundColor: "#FFFFFF",
  },
})

const ContentWrap = styled(Box)({
  width: "100%",
  maxWidth: "942px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  paddingTop: "44px",
  paddingBottom: "45px",
  alignItems: "center",
  "@media (max-width: 768px)": {
    width: "90%",
  },
  "& .contenTexttWrap" : {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    paddingLeft: "6px",
    "@media (max-width: 768px)": {
      paddingLeft:"0px",
    },
  }
})

const GradWrap = styled(Box)({
  display: "flex",
  justifyContent: "space-around",
  marginTop: "50px",
  maxWidth: "1050px",
  width: "100%",
  "@media (max-width: 768px)": {
    flexDirection: "column",
    gap: "20px",
    textAlign: "center"
  },
})

const BottomWrapper = styled(Box)({
  width: "100%",
  backgroundColor: "#5E70DD",
  display: "flex",
  justifyContent: "space-around",
  marginTop: "40px",
  "@media (max-width: 768px)": {
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    display:"none"
  },
})
const BottomWrapperss = styled(Box)({
  width: "100%",
  display:"none",
  backgroundColor: "#5E70DD",
  marginTop: "40px",
  paddingBottom: "1px",
  paddingTop:"1px",
  "@media (max-width: 768px)": {
    display: "block"
  },
})
const TermsWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  "@media (max-width: 768px)": {
    justifyContent: "center",
  },
})
const NavWrap = styled(Box)({
  width: "293px",
  borderLeft: "1px solid #D6DDEB",
  height: "48px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: "84px",
  "@media (max-width: 768px)": {
    display: "none"
  },
})
const MainNav = styled(Box)({
  width: "100%",
  height: "74px",
  maxWidth: "1440px",
  border: "1px solid #D6DDEB",
  paddingLeft: "84px",
  paddingRight: "84px",
  display: "flex",
  alignItems: "center",
  "@media (max-width: 768px)": {
    paddingLeft: "10px",
    width: "94%",
  },
})
const StyledImage = styled("img")({
  display: "none",
  "@media (max-width: 768px)": {
    display: "block",
  },
})
const StyledButton = styled("button")({
  width: "173px",
  height: "50px",
  padding: "16px",
  borderRadius: "12px",
  border: "2px",
  color: "#FFFFFF",
  backgroundColor: "#5E70DD",
  fontFamily: "Manrope",
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "20px",
  letterSpacing: "0.5px",
  textTransform: "capitalize" as "capitalize",
  "@media (max-width: 768px)": {
    width: "309px",
  },
})  

const StyledTyp = styled("span")({
  fontFamily: "Caudex",
  fontSize: "40px",
  fontWeight: 400,
  lineHeight: "44px",
  color: "#353535",
  "@media (max-width: 768px)": {
   textAlign: "center"
  },
})
const StepperWrapper =styled(Box)({
  width:"100%",
  backgroundColor:"white",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  "& .maintainWrap": {
    display: "flex",
    alignItems: "center",
    paddingLeft:"27px",
    paddingTop:"30px",
    paddingBottom: "30px",
    gap: "16px",
    flexWrap: "wrap",
    maxWidth: "1272px",
    width:"100%",
    borderRadius: "13px",
    marginTop:"24px",
    backgroundColor:"#F1F5F9",
    "@media (max-width: 768px)": {
      justifyContent: "center"
     },
  },
  "& .thankText": {
    fontFamily: "Manrope",
    fontSize: "17.53px",
    fontWeight: 500,
    lineHeight: "23.94px",
  },
  "& .littleText": {
    fontFamily: "Manrope",
    fontSize: "17.53px",
    fontWeight: 300,
    lineHeight: "29.31px"
  }
})

const Wrapper = styled(Box)({
  "& .MuiStepIcon-root": {
    color: "#F8FAFB",
  },
  "& .MuiStepIcon-root.Mui-active": {
    color: "#5E70DD",
  },
  "& .MuiStepIcon-root.Mui-completed": {
    color: "#5E70DD",
  },
  "& .css-vnkopk-MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel": {
    zIndex: "99999",
  },
  "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root": {
    height: "2em !important",
    width: "2em !important",
  },
  "& .css-1qv8q39-MuiStepConnector-line": {
    borderTopWidth: 12,
    borderColor: "#F8FAFB",

  },
  "& .css-z7uhs0-MuiStepConnector-line": {
    borderTopWidth: 12,
    borderColor: "#F8FAFB",
  },
  "& .css-117w1su-MuiStepIcon-text": {
    fill: "#5E70DD",
  },
});

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: "17px",
    left: "calc(-50% + 20px)",
    right: "calc(50% + 20px)", 
  },
  [`&.${stepConnectorClasses.line}`]: {
    borderTopWidth: 12, 
    borderColor: "#F8FAFB", 
  },
  [`&.${stepConnectorClasses.active}`]: {
    "& .MuiStepConnector-line": {
      borderColor: "#5E70DD",
      borderTopWidth: 12,
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    "& .MuiStepConnector-line": {
      borderColor: "#5E70DD",
      borderTopWidth: 12,
    },
  },
}));

   

// Customizable Area End
