import React from "react";
import { StyleSheet } from "react-native";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  styled
} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

import {
  CheckCircle, RemoveCircle, VisibilityOutlined, VisibilityOffOutlined,
} from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './emailAccount.css'


import { logo, imgSeniorYearBg, imgSeniorYearCap, Gradient } from "./assets";

interface ArrayData {
  attributes: {
    name: string
  };
  id: string
};

const StyledTypography = styled(Typography)({
  "@media (max-width: 460px)": {
      fontFamily:"Manrope !important"
  }
});

const StyledButton = styled(Button)({
  "@media (max-width: 460px)": {
      fontFamily:"Manrope !important"
  }
});



export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);

  }

  renderAlert = () => {
    setTimeout(()=>{
        this.setState({showCheckboxError: false })
    },5000)
    return <Box style={{
      width:"360px", display:"flex", 
      justifyContent:"center", alignItems:"center" ,
      height:"48px", borderLeft:"4px solid #DC2626", 
      borderRadius:"4px", background: "#FEE2E2", padding:"10px 4px"}}>
    <StyledTypography style={{ color:"#DC2626",fontFamily: "Inter",
        fontSize: "12px",fontWeight: 400,
        lineHeight: "18px",textAlign: "left",
    }}>{"Please check the box to agree with the Privacy Policy and Terms and Conditions to continue."}</StyledTypography>
    </Box>
  }

  renderAlertEmailExist = () => {
    setTimeout(()=>{
        this.setState({showEmailExistError: false })
    },5000)
    return <Box style={{
      width:"360px", display:"flex", 
      justifyContent:"center", alignItems:"center" ,
      height:"48px", borderLeft:"4px solid #DC2626", 
      borderRadius:"4px", background: "#FEE2E2", padding:"10px 4px"}}>
    <StyledTypography style={{ color:"#DC2626",fontFamily: "Inter",
        fontSize: "12px",fontWeight: 400,
        lineHeight: "18px",textAlign: "left",
    }}>{"This email address is already associated with an existing account. Please log in or use a different email to sign up."}</StyledTypography>
    </Box>
  }

  renderInvalidPassword = () => {
    setTimeout(()=>{
      this.setState({showPasswordExistError: false })
  },5000)
  return <Box style={{
    width:"360px", display:"flex", 
    justifyContent:"center", alignItems:"center" ,
    height:"48px", borderLeft:"4px solid #DC2626", 
    borderRadius:"4px", background: "#FEE2E2", padding:"10px 4px"}}>
  <StyledTypography style={{ color:"#DC2626",fontFamily: "Inter",
      fontSize: "12px",fontWeight: 400,
      lineHeight: "18px",textAlign: "left",
  }}>{"Invaild Password"}</StyledTypography>
  </Box>
  }

  render() {
    return (
      <Box className="emailAccountRegistration"
        sx={{overflowX: "hidden",  display: "flex",
         justifyContent:"space-around", backgroundImage: `url(${Gradient.default})` }}>
        <Box
          className="textBoxArea"
          style={{
            display: "flex", alignItems: "center", flexDirection: "column",
            width:"50%"
          }}>
          <Box style={{
            display: "flex", alignItems: "center", flexDirection: "column",
            gap: "24px", padding: "40px 0px"
          }}>
          <Typography className="signupText" style={{
            width: "360px", height: "32px", textAlign: "start", color: "#000000",
            fontFamily: "Inter", fontWeight: 700, fontSize: "24px"
          }}
          > Sign up
          </Typography>
          <img className="imageBg"
            src={`${logo.default}`}
            alt="senior_year"
            />
          {this.state.showCheckboxError && this.renderAlert()}
          {this.state.showEmailExistError && this.renderAlertEmailExist()}
          {this.state.showPasswordExistError && this.renderInvalidPassword()}
          <Box style={{
            width: "360px", height: "fit-content", gap: "16px",
            display: "flex", flexDirection: "column"
          }}>
            <Box style={{
              width: "360px", height: "fit-content", gap: "4px",
              display: "flex", flexDirection: "column"
            }}>
              <StyledTypography style={{
                width: "fit-content", height: "24px", textAlign: "start", color: "#334155",
                fontFamily: "Inter", fontWeight: 700, fontSize: "16px"
              }}
              > I want to
              </StyledTypography>
              <Select
                open={this.state.openMenuDropdown}
                data-test-id="wantToTestBtn"
                className="wantTo"
                style={{
                  width: "360px", height: "56px", borderRadius: "8px", padding: "0px 10px",
                  border: "1px solid #CBD5E1", fontFamily: "Manrope", fontWeight: 400,
                  fontSize: "16px", color: "#252729", outline: "none", cursor: "pointer"
                }}
                onClick={() => this.openMenuItem()}
                onChange={(event: SelectChangeEvent) => this.handleWantTo(event)}
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    onClick={() => this.openMenuItem()}
                    data-testid="keyboardArrowDownIcon"
                    style={{ color: "#94A3B8" }}
                  />
                )}>
                {
                  this.state.wantToData?.map((item: ArrayData) => (
                    <MenuItem value={item.attributes.name} key={item.id}>
                      {item.attributes.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>
            <Box style={{
              width: "360px", height: "fit-content", gap: "4px",
              display: "flex", flexDirection: "column"
            }}>
              <StyledTypography style={{
                width: "fit-content", height: "22px", textAlign: "start", color: "#334155",
                fontFamily: "Inter", fontWeight: 700, fontSize: "16px"
              }}
              > Full name
              </StyledTypography>
              <TextField
                className="fullName" 
                data-test-id="fullNameTestBtn"
                style={{
                  width: "360px", height: "56px", borderRadius: "8px", textAlign: "center",
                  border: "1px solid #CBD5E1", fontFamily: "Inter", fontWeight: 400,
                  fontSize: "16px", color: "#94A3B8", outline: "none",
                  justifyContent: "center",
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFullName(event)}
                placeholder="Your full name"
              >
              </TextField>
            </Box>
            <Box style={{
              width: "360px", height: "fit-content", gap: "4px",
              display: "flex", flexDirection: "column"
            }}>
              <StyledTypography style={{
                width: "fit-content", height: "22px", textAlign: "start", color: "#334155",
                fontFamily: "Inter", fontWeight: 700, fontSize: "16px"
              }}
              > Email
              </StyledTypography>
              <TextField
                data-test-id="emailTestBtn"
                className="email"
                type="email"
                style={{
                  width: "360px", height: "56px", borderRadius: "8px", textAlign: "center",
                  border: `${this.handleErrorBorder()}`, fontFamily: "Inter", 
                  fontSize: "16px", color: "#0F172A",fontWeight: 400, justifyContent: "center",
                }}
                placeholder="Email"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleEmail(event)}
              >
              </TextField>
              {
                !this.state.isEmailValid &&
                <Typography style={{
                  width: "fit-content", height: "18px", textAlign: "start", color: "#DC2626",
                  fontFamily: "Inter", fontWeight: 400, fontSize: "12px"
                }}
                > {this.emailHelperText()}
                </Typography>
              }

            </Box>
            <Box style={{
              width: "360px", height: "fit-content", gap: "4px",
              display: "flex", flexDirection: "column"
            }}>
              <StyledTypography style={{
                width: "fit-content", height: "22px", textAlign: "start", color: "#334155",
                fontFamily: "Inter", fontWeight: 700, fontSize: "16px"
              }}
              > Password
              </StyledTypography>
              <TextField
                data-test-id="passwordTestBtn"
                className="password"
                type={this.state.showPassword ? "text" : "password"}
                style={{
                  width: "360px", height: "56px", borderRadius: "8px", textAlign: "center",
                  border: "1px solid #CBD5E1", fontFamily: "Inter", fontWeight: 400,
                  fontSize: "16px", color: "#0F172A", outline: "none",
                  justifyContent: "center",
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handlePassword(event)}
                placeholder="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => this.handleShowPassword()}
                        edge="end"
                        data-test-id="eyeTestBtn"
                      >
                        {this.state.showPassword ?
                          <VisibilityOutlined
                            style={{ color: "#94A3B8", cursor: "pointer" }}
                          /> :
                          <VisibilityOffOutlined
                            style={{ color: "#94A3B8", cursor: "pointer" }}
                          />
                        }
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              >
              </TextField>
            </Box>
            <Box style={{
              width: "fit-content", display: "flex", justifyContent: "start",
              flexDirection: "column", gap: "2px", alignItems: "flex-start"
            }}>
              <Box style={{ display: "flex", gap: "6px" }}>
                {this.state.hasUpperCase ?
                  <CheckCircle
                    style={{ height: "12px", width: "12px", color: "#34D399" }}
                  /> :
                  <RemoveCircle
                    style={{ height: "12px", width: "12px", color: "#DC2626" }}
                  />
                }
                <StyledTypography style={webStyles.passwordText}>
                  At least one capital letter
                </StyledTypography>
              </Box>

              <Box style={{ display: "flex", gap: "6px" }}>
                {this.state.hasLowerCase ?
                  <CheckCircle
                    style={{ height: "12px", width: "12px", color: "#34D399" }}
                  /> :
                  <RemoveCircle
                    style={{ height: "12px", width: "12px", color: "#DC2626" }}
                  />
                }
                <StyledTypography style={webStyles.passwordText}>
                  At least one lowercase letter
                </StyledTypography>
              </Box>

              <Box style={{ display: "flex", gap: "6px" }}>
                {this.state.hasNumber ?
                  <CheckCircle
                    style={{ height: "12px", width: "12px", color: "#34D399" }}
                  /> :
                  <RemoveCircle
                    style={{ height: "12px", width: "12px", color: "#DC2626" }}
                  />
                }
                <StyledTypography style={webStyles.passwordText}>
                  At least one number
                </StyledTypography>
              </Box>

              <Box style={{ display: "flex", gap: "6px" }}>
                {(this.state.isEmailValid && this.state.email) ?
                  <CheckCircle
                    style={{ height: "12px", width: "12px", color: "#34D399" }}
                  /> :
                  <RemoveCircle
                    style={{ height: "12px", width: "12px", color: "#DC2626" }}
                  />
                }
                <StyledTypography style={webStyles.passwordText}>
                  Invalid email address
                </StyledTypography>
              </Box>
            </Box>
          </Box>
          <Box style={{ width: "360px", display: "flex", flexDirection: "column", gap: "24px" }}>
            <Box style={{ zIndex:99, width: "fit-content", display: "flex", gap: "4px", alignItems: "flex-start" }}>
              <input
                data-test-id="checkboxTestBtn"
                className="termsCheckbox"
                name="terms"
                type="checkbox"
                checked={this.state.checkTerms}
                onChange={(event) => this.handleTermsCheckbox(event)}
              />
              <label
                className="termslable"
                htmlFor="terms"
                style={{
                  width: "280px", fontSize: "14px", color: "#0F172A",
                  fontFamily: "Inter", fontWeight: 400, marginTop:"4px"
                }}
              > I agree with <span data-test-id="privacyPolicyTestBtn" onClick={() => this.navigateToPolicy()}>Privacy Policy</span> and <span data-test-id="tncTestBtn" onClick={() => this.navigateToTermsAndConditions()}>Terms and Conditions</span>
              </label>
            </Box>
            <StyledButton className="signupBtn"
              data-test-id="signupTestBtn"
              variant="contained"
              style={{
                width: "360px", height: "56px", background: "#5E70DD",
                color: "#FFFFFF", fontFamily: "Inter", fontWeight: 700,
                fontSize: "16px", textTransform: "none"
              }}
              onClick={() => this.handleSignUp()}
            > Sign up
            </StyledButton>
          </Box>
          <Box className="loginBtnCtn"
            style={{
              display: "flex", width: "360px", gap: "8px",
              justifyContent: "flex-start"
            }}>
            <StyledTypography style={{
              fontFamily: "Inter", width: "fiit-content", fontWeight: 400, height: "22px",
              fontSize: "16px", color: "#0F172A",
            }}>
              Already have an account?
            </StyledTypography>
            <StyledTypography
              data-test-id="loginTextTestBtn"
              className="loginBtn"
              style={{
                zIndex:99, fontFamily: "Inter", fontWeight: 700, height: "22px", width: "fit-content",
                fontSize: "16px", color: "#747474", cursor: "pointer"
              }}
              onClick={() => this.navigateToLogin()}
            >
              Log in
            </StyledTypography>
          </Box>
          </Box>
        </Box>

        <Box className="imageBox"
          style={{
            width:"55%", position: "relative"
          }}>
          <img
            src={imgSeniorYearBg.default} alt="bg-image"
            style={{ width: "100%" }}
          />
          <img
            src={imgSeniorYearCap.default} alt="cap"
            style={{
              width: "152px", height: "114px",
              position: "absolute", top: "46px", right:"38%"
            }}
          />
        </Box>
      </Box>
    );
  }

}

const webStyles = StyleSheet.create({

  passwordText: {
    width: "fit-content", height: "18px", color: "#0F172A",
    fontFamily: "Inter", fontWeight: "400", fontSize: 12
  }

});
// Customizable Area End
